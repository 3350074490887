import * as React from "react"
import { SkipNavContent, SkipNavLink } from "./skip-nav"
import { Header } from "./header"
import { Footer } from "./footer"
import { Seo } from "./seo"

// interface LayoutProps {
//   i18n?: string
//   children: any
// }

export function Layout(props) {
  const { children, i18n, alternateLink, darkHero, lang } = props
  if (!i18n) {
    return children // à peu près sûr que c'est la 404
  }
  return (
    <div className="flex flex-col min-h-screen">
      <Seo lang={lang} />
      <SkipNavLink />
      <Header i18n={i18n} darkHero={darkHero} alternateLink={alternateLink} />
      <SkipNavContent darkHero={darkHero}>{children}</SkipNavContent>
      {i18n && alternateLink && (
        <Footer i18n={i18n} alternateLink={alternateLink} />
      )}
    </div>
  )
}
