import React, { useState, useEffect, useRef } from "react"
import { HiMenu } from "@react-icons/all-files/hi/HiMenu"
import { HiX } from "@react-icons/all-files/hi/HiX"
import { Link } from "gatsby"

interface BurgerMenuProps {
  links: Array<LinkType>
  i18n: any
}

interface LinkType {
  text: string
  to: string
  hreflang?: string
}

export const BurgerMenu: React.FC<BurgerMenuProps> = function (
  props: BurgerMenuProps
) {
  const { links, i18n } = props
  const [open, setOpen] = useState(false)
  const [extraClass, setExtraClass] = useState("")
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false)
      }
    }

    const header = document.getElementById("header")
    if (open) {
      document.addEventListener("mousedown", handleClickOutside)
      // hrrm not dealing with keyboard navigation to other links, though
      let tempPadding = window.innerWidth - document.body.clientWidth
      header.style.paddingRight = `${tempPadding}px`
      document.body.style.paddingRight = `${tempPadding}px` // otherwise the disapearance of the scrollbar makes content shift https://stackoverflow.com/a/60008044/5941620
      document.body.style.overflow = "hidden" // block scroll when burger is open
      setExtraClass("")
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
      document.body.style.paddingRight = "0"
      header.style.paddingRight = "0"
      document.body.style.paddingRight = "0"
      document.body.style.overflow = "unset"
    }
    setTimeout(() => {
      if (!open) {
        setExtraClass("invisible")
      }
    }, 300) // transition duration (duration-300)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [open, wrapperRef])

  return (
    <div ref={wrapperRef} className="block lg:hidden">
      <button
        type="button"
        aria-label={
          open ? i18n.common.a11y.closeMenu : i18n.common.a11y.openMenu
        }
        onClick={() => setOpen(!open)}
        className="p-2 rounded"
      >
        {open ? (
          <HiX className="w-6 h-6" aria-hidden="true" />
        ) : (
          <HiMenu className="w-6 h-6" aria-hidden="true" />
        )}
      </button>
      <nav
        className={`border-t border-primary-200 text-4xl items-center gap-12 fixed flex flex-col z-30 text-neutral-600 left-0 w-screen top-24 bg-bg pl-8 pb-32 py-6 overflow-scroll transition duration-300 ${
          open ? "translate-x-0 opacity-100	" : "translate-x-48 opacity-0"
        } ${extraClass}`}
        style={{
          height: "calc(100vh)",
          boxShadow: "0px 24px 0px white", // make sure the page behind is not visible
        }}
      >
        {links.map((link: LinkType) => (
          <Link
            key={link.to}
            className="mr-3"
            hrefLang={link.hreflang}
            to={link.to}
            // activeClassName={"text-primary-600"}
            onClick={() => setOpen(false)}
          >
            {link.text}
          </Link>
        ))}
      </nav>
    </div>
  )
}
