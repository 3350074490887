exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-404-jsx": () => import("./../../../src/templates/404.jsx" /* webpackChunkName: "component---src-templates-404-jsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-cart-index-jsx": () => import("./../../../src/templates/cart/index.jsx" /* webpackChunkName: "component---src-templates-cart-index-jsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-faq-tsx": () => import("./../../../src/templates/faq.tsx" /* webpackChunkName: "component---src-templates-faq-tsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-instructions-tsx": () => import("./../../../src/templates/instructions.tsx" /* webpackChunkName: "component---src-templates-instructions-tsx" */),
  "component---src-templates-products-products-jsx": () => import("./../../../src/templates/products/products.jsx" /* webpackChunkName: "component---src-templates-products-products-jsx" */),
  "component---src-templates-products-single-product-jsx": () => import("./../../../src/templates/products/single-product.jsx" /* webpackChunkName: "component---src-templates-products-single-product-jsx" */),
  "component---src-templates-services-tsx": () => import("./../../../src/templates/services.tsx" /* webpackChunkName: "component---src-templates-services-tsx" */)
}

