import * as React from "react"
import { Link } from "gatsby"
import { StoreContext } from "../context/store-context"
import Logo from "../icons/logo"
import { CartButton } from "./cart-button"
// import SearchIcon from "../icons/search"
import { Toast } from "./toast"
// import { searchButton } from "./header.module.css"
import { BurgerMenu } from "./burger-menu"

export function Header({ i18n, darkHero, alternateLink }) {
  const { checkout, loading, didJustAddToCart } = React.useContext(StoreContext)

  const items = checkout ? checkout.lineItems : []

  const quantity = items.reduce((total, item) => {
    return total + item.quantity
  }, 0)

  const links = i18n.slugs
    ? [
        { to: i18n.slugs.products, text: i18n.common.nav.products },
        { to: i18n.slugs.services, text: i18n.common.nav.services },
        // { to: i18n.slugs.lessons, text: i18n.common.nav.lessons },
        { to: i18n.slugs.contact, text: i18n.common.contactUs },
        { to: i18n.slugs.about, text: i18n.common.nav.aboutUs },
      ]
    : []
  const linksBurger = [
    ...links,
    { to: i18n.slugs.instructions, text: "Instructions" },
    { to: i18n.slugs.faq, text: "F.A.Q." },
    {
      to: alternateLink,
      text: i18n.common.oppositeLanguage,
      hreflang: i18n.common.oppositeLang,
    },
  ]

  return (
    <div
      id="header"
      className={`absolute z-10 w-full mt-8 ${
        darkHero ? "text-neutral-100" : "text-neutral-600"
      }`}
    >
      <header className="flex items-center w-full m-auto out-wrapper">
        <Link
          to={i18n.slugs.home}
          aria-label={i18n.common.nav.goHome}
          className="w-64"
        >
          <Logo />
        </Link>
        <div className="flex flex-col items-end ml-auto">
          <nav className="flex items-center text-2xl">
            <div className="hidden lg:block">
              {links.map((link) => {
                return (
                  <div key={link.to} className="mr-3 funky-link">
                    <Link
                      className=""
                      to={link.to}
                      // activeClassName={"funky-active"}
                    >
                      {link.text}
                    </Link>
                  </div>
                )
              })}
            </div>
            <div className="flex">
              {/* <Link to={i18n.slugs.search} className={` ${searchButton}`}>
                <SearchIcon />
              </Link> */}
              <CartButton quantity={quantity} i18n={i18n} />
            </div>
            <BurgerMenu links={linksBurger} i18n={i18n} />
            {/* <Link className="button-full" to={i18n.slugs.products}>
        Shop Slacklines
      </Link> */}
          </nav>
        </div>
      </header>
      <Toast show={loading || didJustAddToCart}>
        {!didJustAddToCart ? (
          i18n.common.updating
        ) : (
          <>
            {`${i18n.common.addedToCart} `}
            <svg
              width="14"
              height="14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.019 10.492l-2.322-3.17A.796.796 0 013.91 6.304L6.628 9.14a1.056 1.056 0 11-1.61 1.351z"
                fill="#fff"
              />
              <path
                d="M5.209 10.693a1.11 1.11 0 01-.105-1.6l5.394-5.88a.757.757 0 011.159.973l-4.855 6.332a1.11 1.11 0 01-1.593.175z"
                fill="#fff"
              />
              <path
                d="M5.331 7.806c.272.326.471.543.815.163.345-.38-.108.96-.108.96l-1.123-.363.416-.76z"
                fill="#fff"
              />
            </svg>
          </>
        )}
      </Toast>
    </div>
  )
}
