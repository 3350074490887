import * as React from "react"
import { StoreProvider } from "./src/context/store-context"
import "./src/styles/reset.css"
import "./src/styles/variables.css"
import "./src/styles/global.css"
import { Layout } from "./src/components/layout.jsx"

export const wrapRootElement = ({ element }) => (
  <StoreProvider>{element}</StoreProvider>
)

export function wrapPageElement({ element, props }) {
  const { i18n, alternateLink, darkHero, lang } = props.pageContext
  console.log("@props gb: ", props)

  return (
    <Layout
      i18n={i18n}
      lang={lang}
      alternateLink={alternateLink}
      darkHero={darkHero}
    >
      {element}
    </Layout>
  )
}
