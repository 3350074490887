import * as React from "react"
import { Link } from "gatsby"
import CartIcon from "../icons/cart"
import { cartButton, badge } from "./cart-button.module.css"

export function CartButton({ quantity, i18n }) {
  return (
    <div className="mr-5 funky-link funky-cart-exception lg:mr-0">
      <Link
        aria-label={`${i18n.common.a11y.cart} ${quantity} items`}
        to={i18n.slugs.cart}
        className={`${cartButton} -top-px flex items-center relative self-center justify-center w-8 ml-1 h-12`}
        activeClassName={"text-primary-600"}
      >
        <CartIcon />
        {quantity > 0 && (
          <div
            className={`flex absolute items-center justify-center h-4 ${badge}`}
          >
            {quantity}
          </div>
        )}
      </Link>
    </div>
  )
}
