import * as React from "react"
import Logo from "../icons/logo"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { FaFlickr } from "@react-icons/all-files/fa/FaFlickr"
import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare"
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube"
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram"
// import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt"
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope"
import { MdLanguage } from "@react-icons/all-files/md/MdLanguage"

export function Footer({ i18n, alternateLink }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            youtube
            instagram
            flickr
            facebook
            # phone
            # phoneHumanFormat
            email
          }
        }
      }
    `
  )
  const {
    facebook,
    youtube,
    instagram,
    flickr,
    // phone,
    // phoneHumanFormat,
    email,
  } = site.siteMetadata

  return (
    <footer className="bg-neutral-500">
      <div className="flex flex-col max-w-6xl pt-10 pb-16 text-white md:justify-between md:flex-row out-wrapper gap-y-16">
        <div className="flex flex-wrap gap-x-10 gap-y-6">
          <section>
            {/* <h3>{i18n.common}</h3> */}
            <h3 className="pb-6 text-2xl font-bold">Informations</h3>
            <ul className="text-lg">
              <li className="mb-2">
                <Link to={i18n.slugs.instructions} className="">
                  Instructions
                </Link>
              </li>
              <li className="mb-2">
                <Link to={i18n.slugs.faq} className="">
                  F.A.Q.
                </Link>
              </li>
              <li className="mb-2">
                <Link to={i18n.slugs.about} className="">
                  {i18n.common.history}
                </Link>
              </li>
            </ul>
          </section>
          <section>
            <h3 className="pb-6 text-2xl font-bold">
              <a href={i18n.slugs.contact}>{i18n.common.contactUs}</a>
            </h3>
            <ul className="text-lg">
              {/* <li className="mb-2">
                <a href={`tel:${phone}`} className="flex">
                  <FaPhoneAlt className="pr-2 w-7 h-7" /> {phoneHumanFormat}
                </a>
              </li> */}
              <li className="mb-2">
                <a href={`mailto:${email}`} className="flex">
                  <FaEnvelope className="pr-2 w-7 h-7" /> {email}
                </a>
              </li>
            </ul>
          </section>
        </div>
        <div className="relative w-64 mx-auto md:mx-0">
          <Link to={i18n.slugs.home} aria-label={i18n.common.nav.goHome}>
            <Logo />
          </Link>
          <section className="flex justify-between pb-16 mt-6">
            <a href={youtube}>
              <FaYoutube className="w-8 h-8" aria-hidden="true" />
              <span className="sr-only">{i18n.common.a11y.youtubeLink}</span>
            </a>
            <a href={facebook}>
              <FaFacebookSquare className="w-8 h-8" aria-hidden="true" />
              <span className="sr-only">{i18n.common.a11y.facebookLink}</span>
            </a>
            <a href={instagram}>
              <FaInstagram className="w-8 h-8" aria-hidden="true" />
              <span className="sr-only">{i18n.common.a11y.instagramLink}</span>
            </a>
            <a href={flickr}>
              <FaFlickr className="w-8 h-8" aria-hidden="true" />
              <span className="sr-only">{i18n.common.a11y.flickrLink}</span>
            </a>
          </section>
          <Link
            className="absolute bottom-0 left-0 flex text-xl font-bold"
            hrefLang={i18n.common.oppositeLang}
            to={alternateLink}
          >
            <MdLanguage className="pr-2 w-7 h-7" />
            {i18n.common.oppositeLanguage}
          </Link>
        </div>
      </div>
    </footer>
  )
}
