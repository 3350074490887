import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

interface LogoProps {
  className?: string
}

function Logo({ className }: LogoProps) {
  return (
    <StaticImage
      className={className}
      src="../assets/images/logo_white_text.png"
      alt="Slackline Mtl's logo"
    />
  )
}

export default Logo
